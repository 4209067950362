import { Injectable } from "@angular/core";
import { AppConfigService } from "../appServiceConfig";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MyDietCommonService } from "../common/services/mydiet-common.service";
import { addendumListAPIResponse } from "../common/models/addendum.model";

@Injectable({
    providedIn: 'root'
})
export class ManualsService {
    constructor(private http: HttpClient,
        private appConfig: AppConfigService,
        private commonService: MyDietCommonService) { }

    getHeadersFileIO(): HttpHeaders {
        const authToken = this.commonService.oAuthToken;
        let headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
        return headers;
    }

    getHeaders(): HttpHeaders {
        const authToken = this.commonService.oAuthToken;
        let headers = new HttpHeaders().set('Content-Type', 'application/json',).set('Authorization', `Bearer ${authToken}`).set('Access-Control-Allow-Origin', '**');
        return headers;
    }

    updateFacilitySpecificDocument(facilityFileId: Number, facilityId: Number, displayName: string, displayOrder: Number, ModifiedBy: string): Observable<any> {
        const headers = this.getHeaders();
        let params = [];
        params.push({ key: "FacilityFileId", value: facilityFileId },
            { key: "FacilityId", value: facilityId },
            { key: "DisplayName", value: displayName },
            { key: "DisplayOrder", value: displayOrder },
            { key: "ModifiedBy", value: ModifiedBy },
        );
        const url = this.commonService.replaceParamsForUrl(environment.urls.updateFacilitySpecificDocument, params);
        return this.http.post<any>(this.appConfig.getConfig().api.uri + url, { headers: headers });
    }

    uploadAddendum(body: any): Observable<any> {
        const headers = this.getHeadersFileIO()
        // // It is very important to leave the Content-Type empty
        // // do not use headers.append('Content-Type', 'multipart/form-data');
        return this.http.post(this.appConfig.getConfig().api.uri + environment.urls.postFacilitySpecificDocument, body, { headers });
    }

    downloadFacilitySpecificDocument(DocumentId: Number, CostCenter: String): Observable<any> {
        const headers = this.getHeadersFileIO();
        let params = [];
        params.push({ key: "FacilityFileId", value: DocumentId });
        params.push({ key: "CostCenter", value: CostCenter });
        const url = this.commonService.replaceParamsForUrl(environment.urls.downloadFacilitySpecificDocument, params);
        return this.http.get(this.appConfig.getConfig().api.uri + url, { headers: headers, responseType: 'blob' });
    }

    getFacilitySpecificDocuments(FacilityId: Number): Observable<addendumListAPIResponse> {
        const headers = this.getHeaders();
        let params = [];
        params.push({ key: "FacilityId", value: FacilityId });
        const url = this.commonService.replaceParamsForUrl(environment.urls.getFacilitySpecificDocumentList, params);
        return this.http.get<addendumListAPIResponse>(this.appConfig.getConfig().api.uri + url, { headers: headers });
    }

    deleteFacilitySpecificDocument(FacilityFileId: Number, CostCenter: string): Observable<any> {
        const headers = this.getHeaders();
        let params = [];
        params.push({ key: "FacilityFileId", value: FacilityFileId });
        params.push({ key: "CostCenter", value: CostCenter });
        const url = this.commonService.replaceParamsForUrl(environment.urls.deleteFacilitySpecificDocument, params);
        return this.http.post(this.appConfig.getConfig().api.uri + url, { headers: headers, responseType: 'blob' });
    }
}