import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MyDietCommonService } from '../../services/mydiet-common.service';

@Component({
  selector: 'app-pre-login-header',
  templateUrl: './pre-login-header.component.html',
  styleUrls: ['./pre-login-header.component.scss']
})
export class PreLoginHeaderComponent {

  @Input() lstDischargePlan: any;
  constructor(private router: Router, private commonService: MyDietCommonService) {
  }

  enableLogin() {
    this.commonService.disableSelfService();
  }

  onSignUp() {
    this.router.navigate(['/signup']);
  }
  scrollTo(event: Event, section: string) {
    event.preventDefault();
    document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
  }
}
